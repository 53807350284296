import React from "react";
import Accordian from "./Accordian";
import { FaqData } from "../Data/FAQsData";
import { Link } from "react-router-dom";

const Faq2 = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-12">
          <div className="flex flex-col gap-5 items-start relative z-[1]">
            {FaqData.slice(0, 9).map((data, i) => {
              return (
                <Accordian
                  key={i}
                  title={data.name}
                  desc={data.para}
                  isOpen={i === 0 ? true : false}
                />
              );
            })}
          </div>
          <div className="flex flex-col gap-5 items-start relative z-[1]">
            {FaqData.slice(0, 9).map((data, i) => {
              return (
                <Accordian
                  key={i}
                  title={data.name}
                  desc={data.para}
                  isOpen={i === 0 ? true : false}
                />
              );
            })}
          </div>
        </div>
        <div className="text-center ">
          <div className="border-[2px] border-dashed border-troo-secondary p-7 sm:inline-flex justify-between items-center ">
            <p className="max-w-[600px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration.
            </p>
            <div className="w-full sm:flex justify-end">
              <Link className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
                VIEW MORE PROJECTS
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq2;
