import React from "react";
import { AddressData } from "../Data/AddressData";
import { motion } from "framer-motion";

const Address = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {AddressData.map((e, i) => (
            <motion.div
            initial={{ x: 50, y : 50, opacity: 0, scale: 0.5 }}
            whileInView={{ x: 0, y: 0,  opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            className="p-5 flex flex-col justify-center items-center gap-3 group">
              <div className="h-24 w-24 text-3xl rounded-full flex justify-center items-center group-hover:bg-troo-yellowfade2 border font-medium">
                {e.img}
              </div>
              <p>{e.para}</p>
              <h4>{e.sub1}</h4>
              <h4>{e.sub2}</h4>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Address;
