import React, { useState } from "react";

const Accordian = ({ title, desc, bgColor, isOpen }) => {
  const [isActive, setIsActive] = useState(isOpen ? true : false);

  const handleAccordionClick = (e) => {
    setIsActive(e.target.checked && !isActive);
  };
  return (
    <div
      className={`collapse collapse-arrow border border-troo-whitefade2 cursor-pointer rounded ${
        isActive
          ? "bg-troo-white text-troo-deepblack collapse-open"
          : `${
              bgColor || "text-troo-secondary"
            } text-troo-stateTertiary collapse-close`
      }`}
    >
      <input type="radio" name="my-accordion-1" onClick={handleAccordionClick} checked="checked" />
      <div
        className={`collapse-title text-troo-deepblack font-Montserrat font-bold px-3 md:px-4
         ${isActive ? " text-troo-primary active mb-3 " : ""}`}
      >
        <h4 className={`pr-8 ${isActive ? "pb-3 border-b border-x-troo-fadblue" : ""}`}>{title}</h4>
      </div>
      <div className="collapse-content px-4 flex gap-3">
        <p >{desc}</p>
      </div>
    </div>
  );
};

export default Accordian;
