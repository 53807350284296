import React, { useEffect, useState } from "react";
import { FiChevronRight } from "react-icons/fi";
import { Link, createSearchParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";
import { BsPersonPlus } from "react-icons/bs";
import { IoPricetagOutline } from "react-icons/io5";
import { motion } from "framer-motion";

const Blogs = ({ isHomePage }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHomePage) {
      setData(BlogData.slice(0, 3));
    } else {
      setData(BlogData);
    }
  }, []);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        {isHomePage && (
          <div className="text-center flex flex-col items-center gap-5 mb-12">
            <div>
              <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                News & Articles
              </p>
            </div>
            <h2 className="max-w-[700px] mx-auto">
              What's going on in our blog? Let’s see our blogs
            </h2>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {data.map((e, i) => (
            <motion.div
              initial={{ x: -100, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: "100", delay: 0.3 * i }}
            >
              <Link
                className="transition-all duration-500 ease-in-out group  rounded"
                key={i}
                to={`/our-blogs/blog-details?${createSearchParams({
                  id: e.id,
                })}`}
              >
                <div className="relative rounded-t overflow-hidden bg-troo-secondary transition-all ease-in-out duration-500">
                  <img
                    className="w-full group-hover:opacity-40 transition-all ease-in-out duration-500"
                    src={e.img}
                    alt={e.name}
                  />
                  <div className="absolute top-5 left-5">
                    <h5 className="bg-troo-primary text-troo-white p-2 text-center">
                      {e.date}
                    </h5>
                    <h5 className="bg-troo-secondary text-troo-white p-2 text-center">
                      {e.year}
                    </h5>
                  </div>
                </div>
                <div className=" flex flex-col gap-5 p-2 md:p-6 bg-troo-whitefade border border-troo-whitefade2 rounded-b shadow-sm">
                  <div className="flex flex-wrap ">
                    <div className="p-2 rounded bg-troo-fadblue flex items-center gap-2">
                      <IoPricetagOutline />
                      {e.tagName}
                    </div>
                    <div className="p-2 rounded bg-troo-fadblue flex items-center gap-2">
                      <BsPersonPlus />
                      {e.bloger}
                    </div>
                  </div>
                  <h3 className="h-[100px]">{e.name}</h3>
                  <div className="border-t-2 border-troo-whitefade2 opacity-50"></div>
                  <p className="font-bold flex items-center gap-2">
                    Read Full Blog <FiChevronRight />
                  </p>
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        {isHomePage && (
          <div className="text-center mt-12">
            <Link to="/our-blogs" className="btn1 bg-troo-secondary hover:bg-troo-primary text-troo-white hover:text-troo-secondary">
              READ ALL BLOGS
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
