import { FaStar } from 'react-icons/fa';
import client1 from "../images/client1.png"
import client2 from "../images/client2.png"
import client3 from "../images/client3.png"
import client4 from "../images/client4.png"
import client5 from "../images/client5.png"
import client6 from "../images/client6.png"
import client7 from "../images/client7.png"
import client8 from "../images/client8.png"
import client9 from "../images/client9.png"


export const FeedbackData = [
    {
        id : 1,
        img : client1,
        name : "Awesome Services!",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Jessica Albert",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 2,
        img : client2,
        name : "Great Team!!!",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Jerremy Peterson",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 3,
        img : client3,
        name : "Great Place to Work.",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Clara Ebert",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 4,
        img : client4,
        name : "Awesome Services!",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Rita Herman",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
   {
        id : 5,
        img : client5,
        name : "Great Team!!!",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Randolph Quigley",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
   {
        id : 6,
        img : client6,
        name : "Great Place to Work.",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Clark Bogan",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
   {
        id : 7,
        img : client7,
        name : "Awesome Services!",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Craig Legros",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 8,
        img : client8,
        name : "Great Team!!!",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Kristine Graham",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
    {
        id : 9,
        img : client9,
        name : "Great Place to Work.",
        para : "Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore ut enim ad minim veniamLorem ipsum dolor amet consectetur do usmod.",
        Cname :"Erick Bergstrom",
        Cdesg : "XYZ Company",
        rating : <><FaStar/><FaStar/><FaStar/><FaStar/><FaStar/></>
    },
]