import caseStudy1 from "../images/caseStudy1.png"
import caseStudy2 from "../images/caseStudy2.png"
import caseStudy3 from "../images/caseStudy3.png"
import caseStudy4 from "../images/caseStudy4.png"
import caseStudy5 from "../images/caseStudy5.png"
import caseStudy6 from "../images/caseStudy6.png"
import caseStudy7 from "../images/caseStudy7.png"
import caseStudy8 from "../images/caseStudy8.png"
import caseStudy9 from "../images/caseStudy9.png"


export const CaseStudyData = [
    {
        id : 1,
        img : caseStudy1,
        name :"Managing & Controlling",
        sub :"Offices"
    },
    {
        id : 2,
        img : caseStudy2,
        name :"Construction Work",
        sub :"Overbridge"
    },
    {
        id : 3,
        img : caseStudy3,
        name :"Over bridge",
        sub :"Airport"
    },
    {
        id : 4,
        img : caseStudy4,
        name :"Interior Design",
        sub :"Airport"
    },
    {
        id : 5,
        img : caseStudy5,
        name :"Repair & Painting",
        sub :"Airport"
    },
    {
        id : 6,
        img : caseStudy6,
        name :"Heavy Equipment",
        sub :"Overbridge"
    },
    {
        id : 7,
        img : caseStudy7,
        name :"Architecture & Building",
        sub :"Overbridge"
    },
    {
        id : 8,
        img : caseStudy8,
        name :"Expert Mechanical",
        sub :"Offices"
    },
    {
        id : 9,
        img : caseStudy9,
        name :"Apartment Design",
        sub :"Offices"
    },

]