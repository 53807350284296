import React from "react";
import serviceDtls from "../images/serviceDtls.png";
import { FiCheckSquare } from "react-icons/fi";

const ServiceDetails = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-5">
          <h2>Managing & controlling all your construction work</h2>
          <div>
            <img className="w-full" src={serviceDtls} alt="serviceDetailsImg" />
          </div>
          <h3>What is known as construction management?</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable. The generated Lorem
            Ipsum is therefore always free from repetition, injected humour, or
            non-characteristic words etc.
          </p>
          <h3>Benefits of construction Service Management</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet.
          </p>
          <div className="flex flex-col gap-2">
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Reduce operational costs & Improve skills
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Risk-free implementation of IT changes
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Improve accountability through standardization
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Improve accountability within business functions
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Improve visibility into operations & Improve performance
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              TRoo Themes give unparalleled feedback to the clinician
            </p>
          </div>
          <h3>Main features</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything.
          </p>
          <div className="flex justify-center mx-auto">
            <div className="p-5 text-xl font-Lato bg-troo-secondary text-troo-white border-l-[5px] md:w-[80%] border-troo-primary">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
