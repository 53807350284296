import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BrandLogos from '../../Components/BrandLogos'
import Teams from '../../Components/Teams'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamPage = () => {
  useDocumentTitle("TRoo Construction | Teams")
  return (
    <Layout>
        <BannerGlobal/>
        <Teams isTeam/>
        <BrandLogos/>
    </Layout>
  )
}

export default TeamPage