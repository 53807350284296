import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BrandLogos from '../../Components/BrandLogos'
import PricingCard from '../../Components/PricingCard'
import useDocumentTitle from '../../Hooks/PageTitle'

const PricingCardPage = () => {
  useDocumentTitle("TRoo Construction | Pricing Cards")
  return (
    <Layout>
        <BannerGlobal/>
        <PricingCard/>
        <BrandLogos/>
    </Layout>
  )
}

export default PricingCardPage