import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BrandLogos from '../../Components/BrandLogos'
import ProjectDetails from '../../Components/ProjectDetails'
import CaseStudy from '../../Components/CaseStudy'
import useDocumentTitle from '../../Hooks/PageTitle'

const ProjectDetailsPage = () => {
  useDocumentTitle("TRoo Construction | Project Details")
  return (
    <Layout>
        <BannerGlobal/>
        <ProjectDetails/>
        <CaseStudy isDetails/>
        <BrandLogos/>
    </Layout>
  )
}

export default ProjectDetailsPage