import React from "react";
import Accordian from "./Accordian";
import { FaqData } from "../Data/FAQsData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Faqs = () => {
  return (
    <div className="bg-faq-bg bg-no-repeat w-full py-24 lg:py-24 bg-troo-whitefade">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-7 mb-12">
          <motion.div
          initial={{x : -200}}
          whileInView={{x : 0}}
          transition={{ duration: 2, type: "spring" }}>
            <div className="flex flex-col gap-5 mb-8">
              <div>
                <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                  Frequently Asked Questions
                </p>
              </div>
              <h2 className="max-w-[600px]">
                Most common asked questions by our clients
              </h2>
            </div>
            <div className="flex flex-col gap-5 items-start relative z-[1]">
              {FaqData.slice(0, 6).map((data, i) => {
                return <Accordian key={i} title={data.name} desc={data.para} isOpen={i === 0 ? true : false} />;
              })}
            </div>
          </motion.div>
          <motion.div
          initial={{x : 200}}
          whileInView={{x : 0}}
          transition={{ duration: 2, type: "spring" }}
          className="bg-troo-secondary p-5 md:p-12 flex flex-col gap-5 text-troo-white rounded">
            <div>
              <Link className="btn1 bg-troo-primary">Contact With Us</Link>
            </div>
            <h2>Request a call back</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration.
            </p>
            <form className="mt-6 w-full">
              <div className="flex flex-col gap-y-8">
                <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:gap-7">
                  <div className="w-full">
                    <p className="mb-3">First Name</p>
                    <input
                      type="text"
                      placeholder="First Name"
                      className="p-4 border border-troo-yellowfade2 focus:outline-none focus-visible:ring-1 rounded w-full bg-transparent"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Last Name</p>
                    <input
                      type="number"
                      placeholder="Last Name"
                      className="p-4 border border-troo-yellowfade2 focus:outline-none focus-visible:ring-1 rounded w-full bg-transparent"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Enter Your Email</p>
                    <input
                      type="email"
                      placeholder="Email"
                      className="p-4 border border-troo-yellowfade2 focus:outline-none focus-visible:ring-1 rounded w-full bg-transparent"
                    />
                  </div>
                  <div>
                    <p className="mb-3">Enter Your Phone</p>
                    <input
                      type="text"
                      placeholder="Enter Your Phone"
                      className="p-4 border border-troo-yellowfade2 focus:outline-none focus-visible:ring-1 rounded w-full bg-transparent"
                    />
                  </div>
                </div>
                <div className="w-full">
                  <p className="mb-3">Write Comment</p>
                  <textarea
                    placeholder="White something to me"
                    className="p-4 border border-troo-yellowfade2 focus:outline-none focus-visible:ring-1 rounded w-full bg-transparent"
                    rows={4}
                  />
                </div>
                <div>
                  <Link className="btn1 bg-troo-primary text-troo-secondary">Post Comment</Link>
                </div>
              </div>
            </form>
          </motion.div>
        </div>
        <div className="text-center ">
          <div className="border-[2px] border-dashed border-troo-secondary p-7 sm:inline-flex justify-between items-center ">
            <p className="max-w-[600px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration.
            </p>
           <div className="w-full sm:flex justify-end">
           <Link to="/faq" className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
           VIEW ALL FAW’s
            </Link>
           </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faqs;
