import React from "react";
import { BrandData } from "../Data/BrandData";
import { motion } from "framer-motion";

const BrandLogos = () => {
  return (
    <div className="py-12 lg:py-24 bg-troo-whitefade">
      <div className="main_container1">
        <div className="text-center mb-10">
          <h3>
            TRUSTED BY <span className="text-troo-primary">1254+</span>{" "}
            CUSTOMERS WORLDWIDE
          </h3>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-8">
          {BrandData.map((e, i) => (
            <motion.div
            initial={{ x: 50, y : 50, opacity: 0, scale: 0.5 }}
            whileInView={{ x: 0, y: 0,  opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: "100", delay: 0.1 * i }}
              key={i}
              className="p-6 bg-troo-white border border-troo-whitefade2 flex justify-center items-center"
            >
              <img src={e.img} alt={e.img} />
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BrandLogos;
