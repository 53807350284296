import React from "react";
import buildings from "../images/buldingImg.png";
import { HighlightData } from "../Data/HighlightData";
import { motion } from "framer-motion";

const HIghtlights = () => {
  return (
    <div className="bg-highlight-bg bg-no-repeat w-full bg-cover h-full relative z-[1] before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:opacity-95 before:z-[-1] ">
      <div className="main_container1 py-12 lg:py-24">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 text-troo-white mb-10">
          <div className="flex flex-col gap-5 ">
            <motion.div
             initial={{ y: -100, opacity: 0 }}
             whileInView={{ y: 0, opacity: 1 }}
             transition={{ duration: 2, type: "spring"}}>
              <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                Why Choose Our Services
              </p>
            </motion.div>
            <h2>Reason we are the best in this business sinse 30+ years</h2>
          </div>
          <div className="flex items-end justify-end">
            <p className="max-w-[500px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some.
            </p>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row  justify-between gap-12">
          <div className="lg:max-w-[35%]  ">
            <motion.div
            initial={{ x: -200, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 2, type: "spring"}}
            className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-primary before:transform before:rotate-1 before:rounded before:right-0 before:top-0 before:z-0 ">
              <img
              
                className="z-1 relative w-full"
                src={buildings}
                alt="building"
              />
            </motion.div>
          </div>
          <div className="lg:max-w-[65%] ">
            <div className="w-full flex flex-col gap-8">
              {HighlightData.slice(0, 6).map((e, i) => (
                <motion.div
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{
                    type: "spring",
                    stiffness: "100",
                    delay: 0.3 * i,
                  }}
                  className="group hover:bg-troo-white hover:bg-opacity-5  md:gap-5  border-t border-b hover:border-b-0 border-troo-white  border-opacity-5 flex flex-col md:flex-row  items-center py-8  px-0 lg:px-4 text-troo-white"
                  key={i}
                >
                  <div className="flex gap-3 items-center w-full">
                    <div className=" text-troo-secondary font-bold bg-troo-primary rounded-full flex justify-center items-center w-10 h-10 transition-all ease-in-out duration-500">
                      0{e.id}
                    </div>
                    <h4>{e.name} </h4>
                  </div>
                  <div className="w-full">
                    <p className=" py-5 md:py-0">{e.para}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HIghtlights;
