import React, { useEffect, useRef } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import logo from "../images/Logo.svg";
import { CiLocationOn } from "react-icons/ci";
import { HeaderData } from "../Data/HeaderData";
import NavMenu from "./NavMenu";
import chatIcon from "../images/chatIcon.svg";
import { RiMenu3Fill } from "react-icons/ri";
import logo2 from "../images/Logo2.svg";
import AccordianMenu from "./AccordianMenu";


const Header = () => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="main_container2 ">
          <nav className="py-4 lg:py-0">
            <div className="flex justify-between items-center">
              <div className="mr-7 ">
                <Link to="/">
                  <img
                    className="max-w-[200px] md:max-w-[280px]"
                    src={logo}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="lg:flex hidden items-center ">
                <ul className="flex h-full">
                  {HeaderData.slice(0, 7).map((item, i) => {
                    return <NavMenu key={i} item={item} />;
                  })}
                </ul>
              </div>
              <div className="hidden 2xl:flex items-center bg-troo-primary h-full">
                <div className="flex items-center gap-3 p-5">
                  <img src={chatIcon} alt="chatIcon" />
                  <div className="text-troo-white">
                    <h4>+44 123 456 7890</h4>
                    <h4>example@email.com</h4>
                  </div>
                </div>
              </div>
              <div className="md:block lg:hidden drawer-content">
                <label htmlFor="my-drawer-2">
                  <RiMenu3Fill className="text-[30px] cursor-pointer" />
                </label>
              </div>
            </div>
          </nav>
        </div>
        <div className="drawer-side z-100">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <div className="menu flex flex-col p-4 w-[80%] md:w-80 h-full overflow-scroll  bg-troo-secondary text-troo-white">
            <div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    src={logo2}
                    alt="logo"
                    className="w-[200px] md:w-[200px] xl:w-[250px]"
                  />
                </Link>
              </div>
              <div className="flex w-full flex-col items-start gap-2 lg:gap-6 2xl:gap-8">
                {HeaderData.slice(0, 7).map((e, i) => {
                  return !e?.subNav ? (
                    <Link
                      key={i}
                      to={e.path}
                      className={`py-1 flex items-center capitalize text-lg ${
                        e.title === lastvalue ? "text-troo-primary" : ""
                      }`}
                    >
                      {e.title}
                    </Link>
                  ) : (
                    <AccordianMenu title={e.title} subMenu={e.subNav} />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="drawer drawer-end">
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <header>
          <div className="bg-troo-secondary">
            <div className="main_container2  z-10">
              <div className="top_header md:flex hidden justify-between items-center flex-wrap py-3  ">
                <div className="flex gap-2 items-center text-troo-white">
                  <CiLocationOn className="text-troo-primary" />
                  <p>17 Drury Street, Las Vegas,nv, 89108 United States</p>
                </div>
                <div className="text-troo-white">
                  <p>
                    Welome Messege “ write something
                    <span className="text-troo-primary"> here </span>”
                  </p>
                </div>
                <div className="flex items-center gap-3 text-troo-white">
                  <p>Follow us on:</p>
                  <Link className="hover:bg-troo-primary bg-troo-yellowfade p-2 hover:text-troo-white rounded transition-all ease-in-out duration-500">
                    <FaFacebookF />
                  </Link>
                  <Link className="hover:bg-troo-primary bg-troo-yellowfade p-2 hover:text-troo-white rounded transition-all ease-in-out duration-500">
                    <FaInstagram />
                  </Link>
                  <Link className="hover:bg-troo-primary bg-troo-yellowfade p-2 hover:text-troo-white rounded transition-all ease-in-out duration-500">
                    <FaTwitter />
                  </Link>
                  <Link className="hover:bg-troo-primary bg-troo-yellowfade p-2 hover:text-troo-white rounded transition-all ease-in-out duration-500">
                    <FaLinkedinIn />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-troo-white">
            <div className="main_container2  z-10">
              <nav className="py-4 lg:py-0">
                <div className="flex justify-between items-center">
                  <div className="mr-7 ">
                    <Link to="/">
                      <img
                        className="max-w-[200px] md:max-w-[280px]"
                        src={logo}
                        alt="logo"
                      />
                    </Link>
                  </div>
                  <div className="lg:flex hidden items-center ">
                    <ul className="flex h-full">
                      {HeaderData.slice(0, 7).map((item, i) => {
                        return <NavMenu key={i} item={item} />;
                      })}
                    </ul>
                  </div>
                  <div className="hidden 2xl:flex items-center bg-troo-primary h-full">
                    <div className="flex items-center gap-3 p-5">
                      <img src={chatIcon} alt="chatIcon" />
                      <div className="text-troo-white">
                        <h4>+44 123 456 7890</h4>
                        <h4>example@email.com</h4>
                      </div>
                    </div>
                  </div>
                  <div className="md:block lg:hidden drawer-content">
                    <label htmlFor="my-drawer-2">
                      <RiMenu3Fill className="text-[30px] cursor-pointer" />
                    </label>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </>
  );
};

export default Header;
