import team1 from "../images/team1.png"
import team2 from "../images/team2.png"
import team3 from "../images/team3.png"
import team4 from "../images/team4.png"
import team5 from "../images/team5.png"
import team6 from "../images/team6.png"
import team7 from "../images/team7.png"
import team8 from "../images/team8.png"
import team9 from "../images/team9.png"
import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaLinkedinIn,
  } from "react-icons/fa";


export const TeamData =[
    {
        id :1,
        img : team1,
        name :"Sasha Bliss",
        sub : "CEO & Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :2,
        img : team2,
        name :"William Flair",
        sub : "Project Manager",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :3,
        img : team3,
        name :"John Martin",
        sub : "Map Designer",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :4,
        img : team4,
        name :"Jenny Scooter",
        sub : "CEO and Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :5,
        img : team5,
        name :"Stefanie Jordan",
        sub : "CEO and Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :6,
        img : team6,
        name :"Fanny Navarro",
        sub : "Co Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :7,
        img : team7,
        name :"Erin Romero",
        sub : "Map Designer",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :8,
        img : team8,
        name :"Della Berg",
        sub : "SR. Manager",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :9,
        img : team9,
        name :"Forest Cherry",
        sub : "SR. Manager",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
   
]