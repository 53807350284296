import React, { useState } from "react";
import counter1 from "../images/counter1.svg";
import counter2 from "../images/counter2.svg";
import counter3 from "../images/counter3.svg";
import counter4 from "../images/counter4.svg";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger"



const Counter = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-12" >
      <div className="bg-troo-whitefade hover:bg-troo-white transition-all ease-in-out duration-500 group rounded-s">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-primary before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full "> 
          <div className="p-5 bg-troo-secondary rounded-full border-4 border-troo-white outline-1 outline-dashed outline-troo-secondary  group-hover:outline-troo-primary group-hover:bg-troo-primary transition-all ease-in-out duration-500">
            <img   src={counter1} alt="counter1" />
          </div>
          <h2 className="group-hover:text-troo-primary transition-all duration-500 ease-in-out">
          <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={1000} end={1260} duration={3} delay={0} />
                  )}
                  <strong>+</strong>
                </ScrollTrigger></h2>
          <p>Happy Clients</p>
        </div>
      </div>
      <div className="bg-troo-whitefade hover:bg-troo-white transition-all ease-in-out duration-500 group">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-primary before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full ">
          <div className="p-5 bg-troo-secondary rounded-full border-4 border-troo-white outline-1 outline-dashed outline-troo-secondary  group-hover:outline-troo-primary group-hover:bg-troo-primary transition-all ease-in-out duration-500">
            <img src={counter2} alt="counter1" />
          </div>
          <h2 className="group-hover:text-troo-primary transition-all duration-500 ease-in-out">
          <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={10} end={25} duration={3} delay={0} />
                  )}
                  <strong>+</strong>
                </ScrollTrigger></h2>
          <p>Year Experience</p>
        </div>
      </div>
      <div className="bg-troo-whitefade hover:bg-troo-white transition-all ease-in-out duration-500 group">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative before:absolute before:w-[1px] before:h-[100px] before:bg-troo-primary before:right-0 before:bottom-0 before:top-0 before:opacity-50 before:m-auto before:transition-all before:ease-in-out before:duration-500 hover:before:h-full ">
          <div className="p-5 bg-troo-secondary rounded-full border-4 border-troo-white outline-1 outline-dashed outline-troo-secondary  group-hover:outline-troo-primary group-hover:bg-troo-primary transition-all ease-in-out duration-500">
            <img src={counter3} alt="counter1" />
          </div>
          <h2 className="group-hover:text-troo-primary transition-all duration-500 ease-in-out">
          <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={1} end={13} duration={3} delay={0} />
                  )}
                  <strong>+</strong>
                </ScrollTrigger></h2>
          <p>World Wide Branches</p>
        </div>
      </div>
      <div className="bg-troo-whitefade hover:bg-troo-white transition-all ease-in-out duration-500 group rounded-e">
        <div className="flex flex-col gap-4 items-center p-5 md:p-10 relative ">
          <div className="p-5 bg-troo-secondary rounded-full border-4 border-troo-white outline-1 outline-dashed outline-troo-secondary  group-hover:outline-troo-primary group-hover:bg-troo-primary transition-all ease-in-out duration-500">
            <img src={counter4} alt="counter1" />
          </div>
          <h2 className="group-hover:text-troo-primary transition-all duration-500 ease-in-out">
          <ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={100} end={230} duration={3} delay={0} />
                  )}
                  <strong>+</strong>
                </ScrollTrigger></h2>
          <p>In-house Staffs</p>
        </div>
      </div>
    </div>
  );
};

export default Counter;
