import React from "react";
import logo2 from "../../images/Logo2.svg";
import { Link } from "react-router-dom";
import { BsArrowDown } from "react-icons/bs";
import { PiChatsCircle } from "react-icons/pi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import useDocumentTitle from "../../Hooks/PageTitle";

const ComingSoonPage = () => {
  useDocumentTitle("TRoo Construction | Coming Soon")
  return (
    <div className="grid grid-cols-1 xl:grid-cols-2 gap-y-7">
      <div className="bg-comingSoon-bg bg-no-repeat min-h-screen bg-center w-full h-full relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:opacity-80 before:z-[-1] z-[1] ">
        <div className="p-8 h-full flex flex-col items-center justify-between">
          <div className="flex justify-end gap-5 text-troo-white font-bold self-end">
            <Link>Home</Link>
            <Link>Contact Us</Link>
          </div>
          <div className="flex flex-col items-center gap-10 text-troo-white">
            <div className="mb-10">
              <img src={logo2} alt="logo2" />
            </div>
            <h2 className="max-w-[500px] text-center">
              We're Launching Our New Website
            </h2>
            <p className="max-w-[450px] text-center">
              We're coming soon! Awesome template to present your future product
              or service.We're working hard to give you the best experience!
            </p>
            <BsArrowDown className="text-3xl" />
            <div>
              <Link className="btn1 bg-troo-primary text-troo-secondary hover:bg-troo-white">
                NOTIFY ME
              </Link>
            </div>
            <div className="flex items-center gap-3">
              <p>Follow us on:</p>
              <FaFacebookF className="w-6 h-6 rounded p-1 bg-troo-white bg-opacity-20 flex justify-center items-center hover:bg-troo-primary  transition-all duration-300 ease-in-out" />
              <FaInstagram className="w-6 h-6 rounded p-1 bg-troo-white bg-opacity-20 flex justify-center items-center hover:bg-troo-primary  transition-all duration-300 ease-in-out" />
              <FaLinkedinIn className="w-6 h-6 rounded p-1 bg-troo-white bg-opacity-20 flex justify-center items-center hover:bg-troo-primary  transition-all duration-300 ease-in-out" />
              <FaTwitter className="w-6 h-6 rounded p-1 bg-troo-white bg-opacity-20 flex justify-center items-center hover:bg-troo-primary  transition-all duration-300 ease-in-out" />
            </div>
          </div>
          <div />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="px-10 py-20 box-border flex flex-col justify-center items-center gap-10 ">
          <h2>Contact With Us</h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="flex flex-wrap gap-5 border border-troo-whitefade2 shadow p-8">
              <div>
                <PiChatsCircle className="rounded-full h-12 w-12 p-3 text-troo-primary bg-troo-yellowfade2" />
              </div>
              <div>
                <p>Call Us on:</p>
                <h4>+44 123 456 7890</h4>
                <h4>info@troothemes.com</h4>
              </div>
            </div>
            <div className="flex flex-wrap gap-5 border border-troo-whitefade2 shadow p-8">
              <div>
                <HiOutlineLocationMarker className="rounded-full h-12 w-12 p-3 text-troo-primary bg-troo-yellowfade2" />
              </div>
              <div>
                <p>Our Location</p>
                <h4>Ewingar Road Lionsville,</h4>
                <h4>New South Wales, 2460 Aus</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
            width="100%"
            height="100%"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            title="gmap"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;
