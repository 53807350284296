import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { BsChatDots, BsPerson, BsSearch, BsTelephone } from "react-icons/bs";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import icon1 from "../images/SdetailsIcon1.svg";
import icon2 from "../images/SdetailsIcon2.svg";
import DetailsImg from "../images/serviceDlts.png";
import servicedetails3 from "../images/serviceDlts3.png";
import Input from "./Input";
import { IoMailOpenOutline } from "react-icons/io5";
import { FiEdit } from "react-icons/fi";
import ServiceCategories from "./ServiceCategories ";
import PopularTags from "./PopularTags";
import RelatedBlogs from "./RelatedBlogs";
import { BlogData } from "../Data/BlogData";

const BlogDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [searchParam] = useSearchParams();
  const id = Number(searchParam.get("id"));

  useEffect(() => {
    const blog = BlogData.find((data) => data?.id === id);
    if (blog) setHeading(blog.name);
    if (blog) setPicture(blog.img);
    window.scrollTo(0, 0);
  }, [id]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-8">
          <div className="w-full lg:w-[70%] flex flex-col items-start gap-5">
            <img
              className="w-full max-h-[500px]"
              src={picture || DetailsImg}
              alt="DetailsImg"
            />
            <h3>
              {heading ||
                "Building worker help each other with at construction site"}
            </h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free text dolore magna aliqua lonm
              andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit
              sed do eiusmod tempor incididunt ut labore et simply free text
              dolore magna aliqua lonm andhn.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simpl.
            </p>
            <h3>We create some things your success future</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor.
            </p>
            <h4 className="bg-troo-whitefade border-t-2  border-troo-primary p-6 rounded ">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything.
            </h4>
            <h3>We create some things your success future</h3>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
              <img
                className="w-full"
                src={servicedetails3}
                alt={servicedetails3}
              />
              <div className="flex flex-col gap-6">
                <div className="bg-troo-whitefade lg:pl-10 px-5 lg:px-0 py-5 pr-5 relative flex flex-col sm:flex-row  gap-5">
                  <div className="inline-flex">
                    <div className="p-3 bg-troo-primary lg:absolute top-[30px] left-[-40px] rounded">
                      <img src={icon1} alt="icon1" />
                    </div>
                  </div>
                  <div>
                    <h4 className="mb-2 ">SEO Consultant</h4>
                    <p>
                      Lorem ipsum dolor sit amet, and consecte tur adipiscing
                      ipsum dolor sit amet elit.
                    </p>
                  </div>
                </div>
                <div className="bg-troo-whitefade lg:pl-10 px-5 lg:px-0 py-5 pr-5 relative flex flex-col sm:flex-row  gap-5">
                  <div className="inline-flex">
                    <div className="p-3 bg-troo-primary  lg:absolute top-[30px] left-[-40px] rounded">
                      <img src={icon2} alt="icon2" />
                    </div>
                  </div>

                  <div>
                    <h4 className="mb-2">Creative Solutions</h4>
                    <p>
                      Lorem ipsum dolor sit amet, and consecte tur adipiscing
                      ipsum dolor sit amet elit.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, which don't look even slightly believable. you need to be
              sure there isn't anything embarrassing hidden in the middle of
              text. All the Lorem Ipsum generators on the Internet tend to
              repeat predefined chunks as necessary.
            </p>
            <div className="bg-troo-whitefade rounded flex flex-col md:flex-row items-center justify-between gap-3 p-4 w-full">
              <div className="flex gap-3">
                <p>Tags:</p> <p>#Building</p> <p>#Housedesign</p>
              </div>
              <div className="flex  items-center gap-3">
                <p>Share This Article:</p>
                <FaFacebookF />
                <FaInstagram />
                <FaLinkedinIn />
                <FaTwitter />
              </div>
            </div>
            <h3 className="mt-7">Comments (0)</h3>
            <h4>Leave A Comment</h4>
            <form className="w-full  flex flex-col items-start gap-14">
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input placeholder="Full Name" icon={<BsPerson />} />
                <Input
                  placeholder="Email Adress"
                  icon={<IoMailOpenOutline />}
                />
                <Input placeholder="Phone No." icon={<BsTelephone />} />
                <Input placeholder="Company Name" icon={<FiEdit />} />
                <div className="flex md:col-span-2 items-start w-full rounded px-5 py-3 justify-between border border-troo-whitefade2">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                  <div className="text-troo-light text-lg">
                    <BsChatDots />
                  </div>
                </div>
              </div>
              <button className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
                Send a Message
              </button>
            </form>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%]  ">
            <div className="bg-troo-whitefade p-3 mb-10">
              <form>
              <Input placeholder="Search" icon={<BsSearch />} />
              </form>
            </div>
            <ServiceCategories />
            <PopularTags />
            <RelatedBlogs currentId={id} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
