

export const HighlightData =[
    {
        id : 1,
        name : "Safety Is Our First Priority",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered."
    },
    {
        id : 2,
        name : "Engineered Structures",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered."
    },
    {
        id : 3,
        name : "Professional Expertise",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered."
    },
    {
        id : 4,
        name : "land Form Othery",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered."
    },
]