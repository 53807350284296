import React from "react";
import Layout from "../../Components/Layout";
import BannerGlobal from "../../Components/BannerGlobal";
import BrandLogos from "../../Components/BrandLogos";
import Faq2 from "../../Components/Faq2";
import useDocumentTitle from "../../Hooks/PageTitle";

const FaqPage = () => {
  useDocumentTitle("TRoo Construction | FAQ's")
  return (
    <Layout>
      <BannerGlobal />
      <Faq2 />
      <BrandLogos />
    </Layout>
  );
};

export default FaqPage;
