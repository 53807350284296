import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Service from '../../Components/Service'
import Teams from '../../Components/Teams'
import Works from '../../Components/Works'
import Faqs from '../../Components/Faqs'
import VideoSection from '../../Components/VideoSection'
import BrandLogos from '../../Components/BrandLogos'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServicePage = () => {
  useDocumentTitle("TRoo Construction | Service")
  return (
    <Layout>
        <BannerGlobal/>
        <Service />
        <Teams/>
        <Works/>
        <Faqs/>
        <VideoSection/>
        <BrandLogos/>
    </Layout>
  )
}

export default ServicePage