import React from "react";
import aboutImg from "../images/aboutImg.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Abouts = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="flex flex-col gap-5">
            <div>
              <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                Construction, since-1980.
              </p>
            </div>
            <motion.h2
              initial={{ x: -200 }}
              whileInView={{ x: 1 }}
              transition={{ duration: 2, type: "spring" }}
            >
              Welcome to TRoo construction. we are professional & reliable.
            </motion.h2>
            <p>
              Lorem Ipsum is simply and dummy text of the and we printing
              industry's typesetting industry. Lorem Ipsum has been the
              industry's & standard dummy text ever since the.Lorem Ipsum is
              simply and dummy text of the and we printing industry's
              typesetting industry. Lorem Ipsum has been the industry's &
              standard dummy text ever since the.
            </p>
            <p>
              Lorem Ipsum is simply and dummy text of the and we printing
              industry's typesetting industry. Lorem Ipsum has been the
              industry's & standard dummy text ever since the.Lorem Ipsum is
              simply and dummy text of the and we printing industry's
              typesetting industry.
            </p>
            <div className="flex flex-col md:flex-row gap-6 text-troo-white">
              <motion.div
                initial={{ y: 100 }}
                whileInView={{ y: 1 }}
                transition={{ duration: 2, type: "spring" }}
                className="p-5 rounded bg-troo-primary flex flex-col gap-3 relative"
              >
                <h2>25+</h2>
                <h4>Years Of Experience in Construction Services</h4>
                <div className="absolute font-Montserrat font-extrabold top-0 right-0 text-[50px] text-troo-white text-opacity-5 ">
                  25+
                </div>
              </motion.div>
              <motion.div
                initial={{ y: 100 }}
                whileInView={{ y: 1 }}
                transition={{ duration: 2, type: "spring" }}
                className="p-6 rounded bg-troo-secondary flex flex-col gap-3 relative"
              >
                <h2>330+</h2>
                <h4>Our Extraordinary Skilled Team Member</h4>
                <div className="absolute font-Montserrat font-extrabold top-0 right-0 text-[50px] text-troo-white text-opacity-5 ">
                  330+
                </div>
              </motion.div>
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <motion.img
            initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 2, type: "spring" }}
              className="w-full border-b-[5px] border-troo-primary rounded"
              src={aboutImg}
              alt="aboutImg"
            />
            <h3>All-encompassing building solutions</h3>
            <p>
              Lorem Ipsum is simply and dummy text of the and we printing
              industry's typesetting industry. Lorem Ipsum has been the
              industry's & standard dummy text ever since the.Lorem Ipsum is
              simply and dummy text.
            </p>
            <div>
              <Link to="/about-us" className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
                LEARM MORE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abouts;
