import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import BrandLogos from '../Components/BrandLogos'
import Address from '../Components/Address'
import Forms from '../Components/Forms'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactPage = () => {
  useDocumentTitle("TRoo Construction | Contact us")
  return (
    <Layout>
        <BannerGlobal/>
        <Address/>
        <Forms/>
        <BrandLogos/>
    </Layout>
  )
}

export default ContactPage