import blog1 from "../images/blog1.png"
import blog2 from "../images/blog2.png"
import blog3 from "../images/blog3.png"
import blog4 from "../images/blog4.png"
import blog5 from "../images/blog5.png"
import blog6 from "../images/blog6.png"
import blog7 from "../images/blog7.png"
import blog8 from "../images/blog8.png"
import blog9 from "../images/blog9.png"


export const BlogData = [
    {
        id : 1,
        img : blog1,
        name : "Building worker help each other with at construction site",
        tagName : "Construction, Building",
        bloger : "Allie Grater",
        date :"10, May",
        year : "2023"
    },
    {
        id : 2,
        img : blog2,
        name : "One of the World Largest Passive House Buildings Solution",
        tagName : "Construction, Building",
        bloger : "William Flair",
        date :"23, May",
        year : "2023"
    },
    {
        id : 3,
        img : blog3,
        name : "Key foature Largest Passive House Buildings Solution",
        tagName : "Construction, Building",
        bloger : "John Martin",
        date :"12, Feb",
        year : "2023"
    },
    {
        id : 4,
        img : blog4,
        name : "You should know everything about build apartment",
        tagName : "Construction, Building",
        bloger : "Jenny Scooter",
        date :"10, May",
        year : "2023"
    },
    {
        id : 5,
        img : blog5,
        name : "Essential Construction KPIs to Improve Profits and Productivity",
        tagName : "Construction, Building",
        bloger : "Stefanie Jordan",
        date :"10, May",
        year : "2023"
    },
    {
        id : 6,
        img : blog6,
        name : "The Secret Sauce to Protecting Construction Profits",
        tagName : "Construction, Building",
        bloger : "Fanny Navarro",
        date :"10, May",
        year : "2023"
    },


    {
        id : 7,
        img : blog7,
        name : "Making the Case for Digital Transformation in Construction",
        tagName : "Construction, Building",
        bloger : "Erin Romero",
        date :"02, Aug",
        year : "2022"
    },
    {
        id : 8,
        img : blog8,
        name : "Have you tried it? Checklist approvals in Autodesk Build Forms",
        tagName : "Construction, Building",
        bloger : "Della Berg",
        date :"10, June",
        year : "2023"
    },
    {
        id : 9,
        img : blog9,
        name : "5 Ways to Protect Your Bottom Line with Autodesk Construction Cloud",
        tagName : "Construction, Building",
        bloger : "Forest Cherry",
        date :"15, Jan",
        year : "2023"
    }
]