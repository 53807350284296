import React from "react";
import { PricingData } from "../Data/PricingData";
import { Link } from "react-router-dom";
import { BsCheckLg } from "react-icons/bs";

const PricingCard = () => {
  return (
    <div className="py-12 lg:py-12">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 gap-y-10 lg:gap-y-20 lg:grid-cols-3  mb-10">
          {PricingData.map((e, i) => (
            <div
              className="group relative bg-troo-whitefade hover:bg-troo-secondary  transition-all duration-500 ease-in-out rounded py-6 "
              key={i}
            >
              <div className="text-center py-5">
                <div className="w-36 h-36 inline-flex flex-col items-center justify-center p-8 bg-troo-white rounded-full relative before:content-[''] before:absolute before:w-full before:h-full before:border before:top-2 before:rounded-full  group-hover:before:border-troo-primary">
                  <div className="text-4xl font-redHate font-bold ">
                    {e.price}
                  </div>
                  <p>per Month</p>
                </div>
              </div>
              <div className="px-8 py-5  flex rounded flex-col gap-4 group-hover:text-troo-white">
                <h4 className="border-y border-troo-whitefade2 group-hover:border-troo-white group-hover:border-opacity-20  py-3 text-center">
                  {e.name}
                </h4>
                <div className="flex flex-col items-start gap-3 self-center">
                  {e.highlights.map((ele, ind) => (
                    <p className={`${ele.className} `} key={ind}>
                      <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                      {ele.heading}
                    </p>
                  ))}
                </div>

                <Link className="btn1 bg-troo-secondary text-troo-white group-hover:bg-troo-primary group-hover:text-troo-secondary">
                  Choose This Plan
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingCard;
