import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BrandLogos from '../../Components/BrandLogos'
import TeamDetails from '../../Components/TeamDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamDetailsPage = () => {
  useDocumentTitle("TRoo Construction | Teams Details")
  return (
   <Layout>
    <BannerGlobal/>
    <TeamDetails/>
    <BrandLogos/>
   </Layout>
  )
}

export default TeamDetailsPage