import brand1 from "../images/brand1.png";
import brand2 from "../images/brand2.png";
import brand3 from "../images/brand3.png";
import brand4 from "../images/brand4.png";
import brand5 from "../images/brand5.png";
import brand6 from "../images/brand6.png";
import brand7 from "../images/brand7.png";
import brand8 from "../images/brand8.png";

export const BrandData = [
  {
    id: 1,
    img: brand1,
  },
  {
    id: 2,
    img: brand2,
  },
  {
    id: 3,
    img: brand3,
  },
  {
    id: 4,
    img: brand4,
  },
  {
    id: 5,
    img: brand5,
  },
  {
    id: 6,
    img: brand6,
  },
  {
    id: 7,
    img: brand7,
  },
  {
    id: 8,
    img: brand8,
  },
];
