import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BrandLogos from '../../Components/BrandLogos'
import BlogDetails from '../../Components/BlogDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogDetailsPage = () => {
  useDocumentTitle("TRoo Construction | Blog Details")
  return (
    <Layout>
        <BannerGlobal/>
        <BlogDetails/>
        <BrandLogos/>
    </Layout>
  )
}

export default BlogDetailsPage