import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Abouts from '../Components/Abouts'
import HIghtlights from '../Components/HIghtlights'
import Testimonials from '../Components/Testimonials'
import Teams from '../Components/Teams'
import Works from '../Components/Works'
import Faqs from '../Components/Faqs'
import VideoSection from '../Components/VideoSection'
import BrandLogos from '../Components/BrandLogos'
import useDocumentTitle from '../Hooks/PageTitle'

const AboutPage = () => {
  useDocumentTitle("TRoo Construction | About us")
  return (
    <Layout>
        <BannerGlobal/>
        <Abouts/>
        <HIghtlights/>
        <Testimonials/>
        <Teams/>
        <Works/>
        <Faqs/>
        <VideoSection/>
        <BrandLogos/>
    </Layout>
  )
}

export default AboutPage