import React, { useEffect, useState } from "react";
import { CaseStudyData } from "../Data/CaseStudyData";
import { Link, createSearchParams } from "react-router-dom";
import { PiArrowUpRightBold } from "react-icons/pi";
import { motion } from "framer-motion";

const CaseStudy = ({ isHomePage, isCaseStudyPage, isDetails }) => {
  const [filterData, setFilterData] = useState(CaseStudyData);
  const [activeTab, setActiveTab] = useState("all");

  const handleFilterList = (field) => {
    setActiveTab(field);
    if (field === "all") {
      setFilterData(CaseStudyData);
    } else {
      setFilterData(CaseStudyData.filter((data) => data.sub === field));
    }
  };
  useEffect(() => {
    if (isCaseStudyPage) {
      setFilterData(CaseStudyData);
    } else {
      setFilterData(CaseStudyData.slice(0, 6));
    }
  }, []);

  return (
    <div
      className={` py-12 lg:py-24 ${
        isHomePage || isDetails
          ? "bg-caseStudy-bg bg-no-repeat  w-full h-full"
          : ""
      }`}
    >
      <div className="main_container1">
        {isHomePage && (
          <div className="text-center flex flex-col items-center gap-5 mb-12">
            <div>
              <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                What We Provide
              </p>
            </div>
            <h2 className="max-w-[600px]">
              The services we provide with perfection
            </h2>
          </div>
        )}
        {isDetails && (
          <div className="text-center mb-12">
            <h2>Construction image & videos</h2>
          </div>
        )}
        {isCaseStudyPage && (
          <div className="flex justify-center flex-wrap gap-6 mb-10">
            {["all", "Offices", "Overbridge", "Airport"].map((tab) => (
              <p
                key={tab}
                className={`px-5 py-3 border inline-flex justify-center border-troo-white text-troo-white rounded  cursor-pointer ${
                  activeTab === tab ? "bg-troo-primary" : " bg-troo-secondary"
                }`}
                onClick={() => handleFilterList(tab)}
              >
                {tab === "all" ? "ALL PROJECTS" : tab}
              </p>
            ))}
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 ">
          {filterData.map((e, i) => (
            <motion.div
            initial={{ x: -100, opacity: 0}}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.3 * i }}>
              <Link
                to={`/our-projects/project-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="relative overflow-hidden w-full group after:content-[''] hover:after:bg-troo-secondary hover:after:bg-opacity-70 after:w-full after:absolute after:top-0 after:left-0 after:h-full after:z-10 before:bg-opacity-0 group-hover:before:bg-opacity-10 before:transition-all before:duration-500 before:ease-in-out"
              >
                <img
                  className="w-full h-full lg:h-[500px] "
                  src={e.img}
                  alt={e.img}
                />
                <div className="absolute left-5 w-[90%] bottom-5 flex justify-center items-center rounded bg-troo-white z-20  opacity-0 group-hover:opacity-100 duration-500 transition-all scale-0 group-hover:scale-100">
                  <div className="p-5 flex flex-col gap-2 justify-center items-center">
                    <p>{e.sub}</p>
                    <h4>{e.name}</h4>
                  </div>
                </div>
                <div className="absolute top-5 right-5 scale-0 group-hover:scale-100 duration-500 transition-all ease-in-out z-20">
                  <PiArrowUpRightBold className="rounded p-3 font-semibold bg-troo-primary w-12 h-12" />
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        {isHomePage && (
          <div className="text-center mt-12">
            <div className="border-[2px] border-dashed border-troo-secondary p-7 sm:inline-flex justify-between items-center ">
              <p className="max-w-[600px]">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration.
              </p>
              <div className="w-full sm:flex justify-end">
                <Link to="/our-projects" className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
                  VIEW MORE PROJECTS
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CaseStudy;
