import React from "react";
import Layout from "../Components/Layout";
import Banner from "../Components/Banner";
import Abouts from "../Components/Abouts";
import Service from "../Components/Service";
import HIghtlights from "../Components/HIghtlights";
import CaseStudy from "../Components/CaseStudy";
import Testimonials from "../Components/Testimonials";
import Teams from "../Components/Teams";
import Works from "../Components/Works";
import Faqs from "../Components/Faqs";
import Blogs from "../Components/Blogs";
import BrandLogos from "../Components/BrandLogos";
import VideoSection from "../Components/VideoSection";
import useDocumentTitle from "../Hooks/PageTitle";

const Home = () => {
  useDocumentTitle("TRoo Construction - React Js Template")
  return (
    <Layout>
      <Banner />
      <Abouts/>
      <Service isHomePage/>
      <HIghtlights/>
      <CaseStudy isHomePage/>
      <Testimonials isHomePage/>
      <Teams/>
      <Works/>
      <Faqs/>
      <VideoSection/>
      <Blogs isHomePage/>
      <BrandLogos/>
    </Layout>
  );
};

export default Home;
