import React from "react";
import img404 from "../../images/404Img.png";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../Hooks/PageTitle";

const ErrorPage = () => {
  useDocumentTitle("TRoo Construction | Error 404")
  return (
    <div className="bg-error-bg w-full bg-no-repeat bg-center py-12 lg:py-36">
      <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <h2>AHHHHHH! YOU FOUND ME!</h2>
          <div >
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2>Page Not Found</h2>
          <h3 className="max-w-[800px] text-center m-auto">
            The page you look for does not exist or another error occured please
            kindly refreash or retun to home page.
          </h3>
          <div className="flex gap-5 justify-center text-troo-white">
            <Link
              to="/"
              className="btn1 bg-troo-primary hover:bg-troo-secondary"
            >
              GO TO HOMEPAGE
            </Link>
            <Link
              to="/contact-us"
              className="btn1 bg-troo-secondary hover:bg-troo-primary"
            >
              GET IN TOUCH
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
