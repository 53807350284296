import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BrandLogos from '../../Components/BrandLogos'
import Testimonials from '../../Components/Testimonials'
import useDocumentTitle from '../../Hooks/PageTitle'

const TestimonialPage = () => {
  useDocumentTitle("TRoo Construction | Testimonials")
  return (
    <Layout>
        <BannerGlobal/>
        <Testimonials/>
        <BrandLogos/>
    </Layout>
  )
}

export default TestimonialPage