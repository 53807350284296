import service1 from "../images/service1.svg"
import service2 from "../images/service2.svg"
import service3 from "../images/service3.svg"
import service4 from "../images/service4.svg"
import service5 from "../images/service5.svg"
import service6 from "../images/service6.svg"
import service7 from "../images/service7.svg"
import service8 from "../images/service8.svg"
import service9 from "../images/service9.svg"


export const ServiceData = [
    {
        id : 1,
        name : "Construction",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service1
    },
    {
        id : 2,
        name : "Project Development",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service2
    },
    {
        id : 3,
        name : "Interior Design",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service3
    },
    {
        id : 4,
        name : "Property Maintenance",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service4
    },
    {
        id : 5,
        name : "Virtual Design & Build",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service5
    },
    {
        id : 6,
        name : "Expert Mechanical",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service6
    },
    {
        id : 7,
        name : "Architecture & Building",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service7
    },
    {
        id : 8,
        name : "Repair & Painting",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service8
    },
    {
        id : 9,
        name : "Bridges & Roads",
        para :"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
        img : service9
    },
]