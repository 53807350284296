import { BiTime, BiPhone } from "react-icons/bi";
import { HiOutlineMailOpen } from "react-icons/hi";
import { CiLocationOn } from "react-icons/ci";

export const AddressData = [
    {
        id : 1,
        img : <BiPhone/>,
        para : "Phone Number",
        sub1 :"+44 132 456 7890",
        sub2 : "+44 987 654 3210"
    },
    {
        id : 2,
        img : <HiOutlineMailOpen/>,
        para : "Email Address",
        sub1 :"contact@troothemes.com",
        sub2 : "info@trootheme.com"
    },
    {
        id : 3,
        img : <CiLocationOn/>,
        para : "Office Address",
        sub1 :"4516 School Street ",
        sub2 : " Danbury, CT, Canada"
    },
    {
        id : 4,
        img : <BiTime/>,
        para : "Opening Hours",
        sub1 :"Mon-Sar: 10AM to 06PM",
        sub2 : "Sunay Closed"
    }
]