import React from "react";
import { Link } from "react-router-dom";
import { FiPlay } from "react-icons/fi";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div className="bg-banner-bg bg-no-repeat bg-cover w-full h-full relative z-[1] before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:opacity-70 before:z-[-1]">
      <div className="main_container1 py-12 lg:py-48">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-8 lg:pb-12">
          <div className="text-troo-white flex flex-col gap-7 ">
            <motion.div
              initial={{ y: -200 }}
              whileInView={{ y: 1 }}
              transition={{ duration: 2, type: "spring" }}
            >
              <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                Construction, since-1980.
              </p>
            </motion.div>
            <motion.h1
              initial={{ x: -200 }}
              whileInView={{ x: 1 }}
              transition={{ duration: 2, type: "spring" }}
            >
              We Make Standards{" "}
              <span className="textShadow">Contruction Work</span>
            </motion.h1>
            <motion.p
              initial={{ x: 200 }}
              whileInView={{ x: 1 }}
              transition={{ duration: 2, type: "spring" }}
            >
              Our mission is to be the business head specialist co-op
              organization focused on satisfying the most extreme to our
              clients.
            </motion.p>
            <motion.div
              initial={{ y: 200 }}
              whileInView={{ y: 1 }}
              transition={{ duration: 2, type: "spring" }}
              className="flex gap-5"
            >
              <Link to="/our-projects" className="btn1 bg-troo-white text-troo-secondary hover:bg-troo-primary">
                GET STARTED
              </Link>
              <Link to="/contact-us" className="btn1 bg-troo-primary hover:bg-troo-white hover:text-troo-secondary">
                Contact us
              </Link>
            </motion.div>
          </div>
          <div className="lg:relative flex justify-center pt-20 lg:pt-0 lg:justify-end">
            <div className="lg:absolute  top-1/2 lg:w-[265px] lg:h-[465px] rounded-full transform -rotate-45 -translate-x-1/2 -translate-y-1/2 bg-troo-yellowfade2  z-10  ">
              <motion.div
               initial={{ transform: "translate(-50%, -50%) rotate(45deg) scale(0.5)" }}
               whileInView={{ transform: "translate(-50%, -50%) rotate(45deg) scale(1)" }}
               transition={{ type: "spring",duration : 2 }}
                className="play"
                onClick={() => window.my_modal_1.showModal()}
              >
                <FiPlay className="h-8 w-8" />
              </motion.div>
              <dialog id="my_modal_1" className="modal">
                <form
                  method="dialog"
                  className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
                >
                  <iframe
                    width="100%"
                    height="500"
                    src="https://www.youtube.com/embed/DUfXdcpEfMs"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowfullscreen
                  ></iframe>
                </form>
                <form method="dialog" className="modal-backdrop">
                  <button>close</button>
                </form>
              </dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
