import React from "react";
import { WorkData } from "../Data/WorkData";
import arrow from "../images/Arrow.svg";
import { motion } from "framer-motion";

const Works = () => {
  const cardElements = WorkData.map((e, i) => (
    <div className=" flex flex-col items-center gap-3 group" key={i}>
      <div className="relative rounded-full border border-troo-secondary border-opacity-10 p-8">
        <div className="bg-troo-whitefade group-hover:bg-troo-primary transition-all ease-in-out duration-500 w-28 h-28  flex justify-center items-center rounded-[50%]">
          <img src={e.img} alt={e.img} />
        </div>
        <p className="absolute group-hover:bg-troo-primary group-hover:text-troo-secondary  bg-troo-secondary text-troo-white w-10 h-10 top-3 right-0 rounded-[50%] flex justify-center items-center font-bold transition-all duration-500 ease-in-out">
          0{e.id}
        </p>
      </div>
      <h4 className="text-center">{e.name}</h4>
      <p className="max-w-[300px] text-center">{e.para}</p>
    </div>
  ));

  const arrowElement = (
    <div className="flex items-center justify-center transform rotate-x-0 rotate-y-0 rotate-90 lg:rotate-0">
      <img className="py-16" src={arrow} alt="sicon" />
    </div>
  );
  return (
    <div className="main_container1  py-12 lg:py-24 rounded">
      <div className="text-center flex flex-col items-center gap-5 mb-12">
        <div>
          <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
            How It’s Work
          </p>
        </div>
        <h2>Our standard working process</h2>
      </div>
      <div className="flex flex-col lg:flex-row">
        {cardElements.map((card, index) => (
          <React.Fragment key={index}>
            {card}
            {index < cardElements.length - 1 && arrowElement}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Works;
