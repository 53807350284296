import React from "react";
import { FiPlay } from "react-icons/fi";
import { Link } from "react-router-dom";

const VideoSection = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 video">
      <div className="bg-videoLeft-bg bg-no-repeat w-full bg-troo-secondary ">
        <div className="flex flex-col gap-7 px-10 py-12 lg:py-40 text-troo-white">
          <div>
            <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
              Frequently Asked Questions
            </p>
          </div>
          <h2 className="max-w-[700px]">
            We build everything with passion & care
          </h2>
          <p className="max-w-[700px]">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable.
          </p>
          <div>
            <Link to="/pricing-plan" className="btn1 bg-troo-primary">DISCOVER MORE</Link>
          </div>
        </div>
      </div>
      <div className="bg-videoRight-bg  bg-no-repeat bg-cover w-full h-full relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:opacity-70 before:z-[-1] z-[1]">
        <div className="py-44">
          <Link className="play" onClick={() => window.my_modal_2.showModal()}>
            <FiPlay className="h-8 w-8" />
          </Link>
          <dialog id="my_modal_2" className="modal">
            <form
              method="dialog"
              className="modal-box w-11/12 max-w-5xl p-0 overflow-hidden"
            >
              <iframe
                width="100%"
                height="450"
                src="https://www.youtube.com/embed/DUfXdcpEfMs"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </form>
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
        </div>
      </div>
    </div>
  );
};

export default VideoSection;
