import React, { useEffect, useState } from "react";
import teamDetailsImg from "../images/teamDetails.png";
import { Link, useSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";
import { AddressData } from "../Data/AddressData";
import teamDetails2 from "../images/teamDetails2.png"

const TeamDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [sub, setSub] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");
  useEffect(() => {
    const filData = TeamData.find((data) => data?.id == id);
    setPicture(filData?.img);
    setHeading(filData?.name);
    setSub(filData?.sub);
  }, [id]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 xl:gap-20">
          <div className=" ">
            <img
              className="w-full lg:max-w-[550px] lg:max-h-[610px] border-[5px] border-troo-primary rounded"
              src={picture || teamDetailsImg}
              alt={teamDetailsImg}
            />
          </div>
          <div className="flex flex-col gap-5">
            <div className="text-xl font-mono">
              {sub || "Company CEO & Founder"}
            </div>
            <h2 className="mb-8">{heading || "Sasha Bliss"}</h2>
            <p>
              The spectacular Harbour Central is a brand new development in
              London’s The Docklands, comprising a total of 642 private
              apartments divided across five residential buildings, along with a
              leisure complex.
            </p>
            <div className="flex flex-col gap-5 py-4">
              {AddressData.slice(0,3).map((e, i) => (
                <div key={i} className="flex gap-5">
                  <div className="rounded-full h-12 w-12 p-2 text-troo-primary flex justify-center items-center bg-troo-yellowfade2">
                    {e.img}
                  </div>
                  <div>
                    <p>{e.para}</p>
                    <h4>{e.sub1}</h4>
                  </div>
                </div>
              ))}
            </div>
            <div>
              <Link className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white">
                GET IN TOUCH
              </Link>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 mt-12">
          <div className="flex flex-col gap-12">
            <h2>Professional Skills</h2>
            <p>The spectacular Harbour Central is a brand new development in London’s The Docklands, comprising a total of 642 private apartments divided across five residential buildings, along with a leisure complex.</p>
            <div className="flex flex-col gap-4 justify-around">
              <div className="flex flex-col gap-3 mb-5 relative">
                <h5>Support & Productivity</h5>
                <div className="h-1 w-full bg-troo-yellowfade2">
                  <div className="h-1 w-[90%] bg-troo-primary"></div>
                </div>
                <h5 className="absolute top-0 right-5">95%</h5>
              </div>
              <div className="flex flex-col gap-4 mb-5 relative" >
                <h5>Troubleshooting</h5>
                <div className="h-1 w-full bg-troo-yellowfade2">
                  <div className="h-1 w-[70%] bg-troo-primary"></div>
                </div>
                <h5 className="absolute top-0 right-5">70%</h5>
              </div>
              <div className="flex flex-col gap-3 relative">
                <h5>Servicing</h5>
                <div className="h-1 w-full bg-troo-yellowfade2">
                  <div className="h-1 w-[80%] bg-troo-primary"></div>
                </div>
                <h5 className="absolute top-0 right-5">80%</h5>
              </div>
            </div>
          </div>
          <div>
            <img src={teamDetails2} alt="teamDetails2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
