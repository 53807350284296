import React from "react";
import { BsChatDots, BsPerson, BsTelephone } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import Input from "./Input";
import { IoMailOpenOutline } from "react-icons/io5";

const Forms = () => {
  return (
    <div className="bg-troo-whitefade">
      <div className="main_container1 pt-10">
        <div className="text-center flex flex-col items-center gap-5 mb-12">
          <div>
            <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
              Would like to discuss?
            </p>
          </div>
          <h2 className="max-w-[600px]">We'd love to hear from you.</h2>
        </div>
        <div className="bg-troo-white p-5 lg:p-12 mb-[-100px] relative z-100 shadow-2xl rounded-lg">
          <form className="w-full  flex flex-col items-start gap-14">
            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
              <Input placeholder="Full Name" icon={<BsPerson />} />
              <Input placeholder="Email Adress" icon={<IoMailOpenOutline />} />
              <Input placeholder="Phone No." icon={<BsTelephone />} />
              <Input placeholder="Website ( optional )" icon={<FiEdit />} />
              <div className="flex md:col-span-2 items-start w-full px-5 py-3 justify-between border border-troo-whitefade2">
                <textarea
                  rows={5}
                  placeholder="Message"
                  className="bg-transparent outline-none w-full"
                />
                <div className="text-troo-light text-lg">
                  <BsChatDots />
                </div>
              </div>
            </div>
            <button className="btn1 bg-troo-secondary hover:bg-troo-primary text-troo-white">
              Send a Message
            </button>
          </form>
        </div>
      </div>
      <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
            width="100%"
            height="500"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            title="gmap"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
    </div>
  );
};

export default Forms;
