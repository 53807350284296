import React from 'react'

const PopularTags = () => {
    const tags = ["Building", "Construction", "Interior", "Design", "Hosting"];
    return (
        <div className="bg-troo-whitefade p-3 w-full mb-10">
          <h4 className="relative mb-5  pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-primary">Popular Tags</h4>
          <div className="flex flex-wrap gap-2 w-full">
            {tags.map(tag => (
              <p key={tag} className="p-2 bg-troo-white hover:bg-troo-primary  rounded transition-all ease-in-out duration-500">{tag}</p>
            ))}
          </div>
        </div>
      );
}

export default PopularTags