import bannerGlobalImg from "../images/globalImg.png";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { BlogData } from "../Data/BlogData";
import { ServiceData } from "../Data/ServiceData";
import { TeamData } from "../Data/TeamData";
import { HeaderData } from "../Data/HeaderData";
import { Link } from "react-router-dom";
import { CaseStudyData } from "../Data/CaseStudyData";
import { FiChevronRight } from "react-icons/fi";

const BannerGlobal = () => {
  const [heading, setHeading] = useState();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  let pathName = location.pathname;

  useEffect(() => {
    const id = searchParams.get("id");
    if (id) {
      if (pathName.includes("blog-details")) {
        const bData = BlogData.find((e) => e.id == id);
        setHeading(bData.name);
      }
      if (pathName.includes("services-details")) {
        const sData = ServiceData.find((e) => e.id == id);
        setHeading(sData.name);
      }
      if (pathName.includes("project-details")) {
        const PData = CaseStudyData.find((e) => e.id == id);
        setHeading(PData.name);
      }
      if (pathName.includes("team-details")) {
        const tData = TeamData.find((e) => e.id == id);
        setHeading(tData.name);
      }
    } else {
      const data = HeaderData.find((data) => {
        if (data?.subNav) {
          const subTitel = data?.subNav?.find((s) => s.path === pathName);
          if (subTitel) {
            return data;
          }
        } else if (data.path === pathName) {
          return data;
        }
      });

      if (data?.subNav) {
        const obj = data?.subNav.find((s) => s.path === pathName);
        setHeading(obj.heading);
      } else {
        setHeading(data.heading);
      }
    }
  }, []);
  useEffect(() => {
    const pathnamee = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnamee.map((e, i) => ({
      title: e,
      path: `/${pathnamee.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);
  return (
    <div className="bg-banner-global bg-no-repeat bg-cover w-full h-full bg-troo-secondary overflow-hidden">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div className="pt-10 md:py-24 flex flex-col gap-5 justify-center items-center md:items-baseline">
            <div className="text-troo-white">
              <ul className="inline-flex items-center  flex-wrap gap-1 justify-center relative">
                <Link to="/">Home</Link>
                <span>
                  <FiChevronRight />
                </span>
                {breadcrumbs.map((breadcrumb, index) => (
                  <React.Fragment key={index}>
                    <li
                      className={`capitalize ${
                        index === breadcrumbs.length - 1
                          ? "text-troo-primary"
                          : ""
                      }`}
                    >
                      <Link
                        to={breadcrumb.path}
                        className={
                          breadcrumb.path === pathName
                            ? "text-troo-primary"
                            : ""
                        }
                      >
                        {breadcrumb.title.replace(/-/g, " ")}
                      </Link>
                    </li>
                    {index !== breadcrumbs.length - 1 && (
                      <span>
                        <FiChevronRight />
                      </span>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </div>
            <h3 className="text-troo-white mb-8">{heading}</h3>
          </div>
          <div className="flex justify-center md:justify-end items-end mt-10">
            <div className="relative before:content-[''] before:absolute before:w-64 md:before:w-96 before:h-64 md:before:h-96 before:bg-troo-primary before:rounded-full before:z-[-1] before:left-1/2 before:-translate-x-1/2  md:before:top-11 before:top-20 z-[1] ">
              <img src={bannerGlobalImg} alt="bannerGlobalImg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerGlobal;
