import React, { useEffect, useState } from "react";
import { FeedbackData } from "../Data/FeedbackData";
import { Link } from "react-router-dom";
import Quote from "../images/quote.svg";
import { motion } from "framer-motion";

const Testimonials = ({ isHomePage }) => {
const [data, setData] =useState([])
useEffect(()=>{
  if(isHomePage){
    setData(FeedbackData.slice(0,3))
  }
  else{
    setData(FeedbackData)
  }
},[])
  return (
    <div
      className={` py-12 lg:py-24  ${
        isHomePage ? "bg-feedback-bg bg-no-repeat w-full h-full bg-troo-whitefade" : ""
      }`}
    >
      <div className="main_container1">
        {isHomePage && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-10">
            <div className="flex flex-col gap-5 ">
              <motion.div
              initial={{x : -100}}
              whileInView={{x : 0}}
              transition={{duration:2 , type: "spring"}}
              >
                <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                  What Client Says?
                </p>
              </motion.div>
              <motion.h2 initial={{x : -100}}
              whileInView={{x : 0}}
              transition={{duration:2 , type: "spring"}} >Explore our very satisfied clients testimonials</motion.h2>
            </div>
            <div className="flex items-end justify-end">
              <motion.p initial={{x : 100}}
              whileInView={{x : 0}}
              transition={{duration:2 , type: "spring"}} className="max-w-[500px]">
                There are many variations of passages of Lorem Ipsum available,
                but the majorityThere are many variations of passages of Lorem
                Ipsum available, but the majority have suffered.
              </motion.p>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
          {data.map((e, i) => (
          <motion.div
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: "100",
            delay: 0.3 * i,
          }}>
              <Link
              key={i}
              className="group bg-troo-white p-5 flex flex-col gap-4 border-t-2 border-troo-secondary border-opacity-10 hover:shadow-lg  transition-all ease-in-out duration-500"
            >
              <div className="flex justify-between gap-5 items-center py-3 border-b-2 border-troo-whitefade ">
                <div className="flex gap-1 text-troo-primary">{e.rating}</div>
                <img src={Quote} alt="quote" />
              </div>
              <h4>{e.name}</h4>
              <p>{e.para}</p>
              <div className="flex gap-5">
                <div className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-primary before:rounded-full before:top-1 before:z-[-1] z-[1]">
                  <img src={e.img} alt={e.name} />
                </div>
                <div>
                  <h4>{e.Cname}</h4>
                  <p>{e.Cdesg}</p>
                </div>
              </div>
            </Link>
          </motion.div>
          ))}
        </div>
      {
        isHomePage && (
          <div className="text-center">
          <Link to="/testimonial" className="btn1 bg-troo-secondary text-troo-white hover:bg-troo-primary">
            VIEW ALL REVIEW
          </Link>
        </div>
        )
      }
      </div>
    </div>
  );
};

export default Testimonials;
