import React, { useEffect, useState } from "react";
import { ServiceData } from "../Data/ServiceData";
import { Link, createSearchParams } from "react-router-dom";
import { BsArrowUpRight } from "react-icons/bs";
import { PiArrowUpRightBold } from "react-icons/pi";
import { motion } from "framer-motion";

import bobbles from "../images/bubbles.svg";
const Service = ({ isHomePage, isDetails }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHomePage) {
      setData(ServiceData.slice(0, 5));
    } else if (isDetails) {
      setData(ServiceData.slice(0, 3));
    } else {
      setData(ServiceData);
    }
  }, []);

  return (
    <div
      className={` py-12 lg:py-24 ${
        isHomePage ? " bg-service-bg w-full bg-no-repeat" : ""
      }`}
    >
      <div className="main_container1">
        {isHomePage && (
          <motion.div
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 2, type: "spring",stiffness: "100" }}
          className="text-center flex flex-col items-center gap-5 mb-12">
            <div>
              <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                What We Provide
              </p>
            </div>
            <h2 className="max-w-[600px]">
              The services we provide with perfection
            </h2>
          </motion.div>
        )}
        {isDetails && (
          <div className="text-center mb-12">
            <h2>Related Services</h2>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-7 ">
          {data.map((e, i) => (
            <motion.div
            initial={{ x: -100, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: "100", delay:0.5 * i }}
            >
              <Link
                to={`/our-services/services-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="px-7 pt-12 rounded overflow-hidden border border-solid border-gray-400 border-opacity-20 bg-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500  relative group flex flex-col gap-4"
              >
                <h3>{e.name}</h3>
                <p>{e.para}</p>
                <div className="flex justify-between items-center mt-5">
                  <div className="py-8 px-6 bg-troo-whitefade  rounded-t-full">
                    <img src={e.img} alt={e.name} />
                  </div>
                  <div className="pr-10 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-300">
                    <BsArrowUpRight className="text-3xl font-semibold" />
                  </div>
                </div>
                <div className="absolute right-0 top-1 opacity-0 group-hover:opacity-100 transition-all ease-in-out duration-600">
                  <img src={bobbles} alt="bubbles" />
                </div>
              </Link>
            </motion.div>
          ))}
          {isHomePage && (
            <Link to="/our-services" className="flex justify-center flex-col gap-5 items-center border border-solid border-gray-400 border-opacity-20  rounded w-full h-full hover:bg-troo-primary transition-all ease-in-out duration-500 group">
              <h5>View All Services</h5>
              <PiArrowUpRightBold className="rounded-full p-2 font-semibold bg-troo-primary group-hover:bg-troo-white w-8 h-8" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Service;
