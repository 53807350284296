import React, { useEffect, useState } from "react";
import { Link, createSearchParams } from "react-router-dom";
import spiral from "../images/spiral.png";
import { TeamData } from "../Data/TeamData";
import Counter from "./Counter";
import { motion } from "framer-motion";

const Teams = ({ isTeam }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isTeam) {
      setData(TeamData);
    } else {
      setData(TeamData.slice(0, 4));
    }
  }, []);
  return (
    <div
      className={
        isTeam
          ? ""
          : "bg-team-bg bg-center bg-no-repeat w-full h-full relative z-[1] before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:opacity-95 before:z-[-1]"
      }
    >
      <div className="main_container1 py-12 lg:py-24">
        {isTeam ? (
          ""
        ) : (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mb-10">
            <div className="flex flex-col gap-5 text-troo-white">
              <div>
                <p className="py-2 px-4  inline-block rounded bg-gradient-to-r from-troo-primary  to-transparent">
                  Meet Our Team
                </p>
              </div>
              <h2>We provide our service with our expert team</h2>
            </div>
            <div className="flex gap-8 items-end justify-end">
              <img src={spiral} alt="spiral" />
              <Link to="/our-team" className="btn1 bg-troo-white text-troo-secondary hover:bg-troo-primary">
                VIEW ALL TEAM
              </Link>
            </div>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 overflow-hidden xl:overflow-visible">
          {data.map((e, i) => (
            <motion.div
            initial={{ x: 50, y : 50, opacity: 0, scale: 0.5 }}
            whileInView={{ x: 0, y: 0,  opacity: 1, scale: 1 }}
            transition={{ type: "spring", stiffness: "100", delay: 0.2 * i }}
            >
              <Link
                to={`/our-team/our-team-details?${createSearchParams({
                  id: e.id,
                })}`}
                key={i}
                className="px-5 py-10 hover:shadow-lg group flex flex-col items-center gap-4 bg-troo-white rounded relative"
              >
                <div className="relative before:content-[''] before:absolute before:rounded-full before:border-8 before:border-troo-primary  before:border-l-transparent before:border-b-transparent before:top-[-8px] before:left-[-7px] before:bottom-[-5px] before:right-[-7px] before:-rotate-45 group-hover:before:rotate-45 before:transition-all before:ease-in-out before:duration-500">
                  <img className="rounded-full" src={e.img} alt={e.img} />
                </div>
                <h3>{e.name}</h3>
                <p>{e.sub}</p>
                <div className="absolute rounded bottom-0 xl:bottom-[-20px] flex gap-2 p-2 bg-troo-primary scale-y-0  group-hover:scale-y-100 transition-all ease-in-out duration-500">
                  {e.social.map((ele) => (
                    <Link key={ele} className="bg-troo-white rounded p-1">
                      {ele.icon}
                    </Link>
                  ))}
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
        {isTeam ? " " : <Counter />}
      </div>
    </div>
  );
};

export default Teams;
