import React, { useEffect, useState } from "react";
import project1 from "../images/projectDetails1.png";
import project2 from "../images/projectDetails2.png";
import { FiCheckSquare } from "react-icons/fi";
import { CaseStudyData } from "../Data/CaseStudyData";
import { useSearchParams } from "react-router-dom";

const ProjectDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");
  useEffect(() => {
    const filData = CaseStudyData.find((data) => data?.id == id);
    setPicture(filData?.img);
    setHeading(filData?.name);
  }, [id]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-5">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="flex flex-col gap-5">
              <h2>{heading || "Overbridge Project"}</h2>
              <p>
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing
                hidden in the middle of text. All the Lorem Ipsum generators on
                the Internet tend to repeat predefined chunks as necessary,
                making this the first true generator on the Internet.
              </p>
            </div>
            <div className="p-5 grid grid-cols-1 md:grid-cols-2 gap-5 bg-troo-whitefade">
              <div className="flex flex-col gap-3">
                <p>Client</p>
                <h4>Global projects</h4>
              </div>
              <div className="flex flex-col gap-3">
                <p>Date</p>
                <h4>Jun 01, 2023</h4>
              </div>
              <div className="flex flex-col gap-3">
                <p>Location</p>
                <h4>Zour, Kuwait</h4>
              </div>
              <div className="flex flex-col gap-3">
                <p>Category</p>
                <h4>Overbridge Work</h4>
              </div>
            </div>
          </div>
          <div className="flex gap-5">
            <img className="w-full max-h-[520px]" src={picture || project1} alt="project1" />
            <img className="w-full" src={project2} alt="project1" />
          </div>
          <h3>The Challenge:</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet. It uses a dictionary of over 200 Latin
            words, combined with a handful of model sentence structures, to
            generate Lorem Ipsum which looks reasonable.
          </p>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text.{" "}
          </p>
          <h3>The Solution</h3>
          <p>
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour, or randomised words which don't look even slightly
            believable. If you are going to use a passage of Lorem Ipsum, you
            need to be sure there isn't anything embarrassing hidden in the
            middle of text. All the Lorem Ipsum generators on the Internet tend
            to repeat predefined chunks as necessary, making this the first true
            generator on the Internet.
          </p>
          <h3>
            The Impact:
          </h3>
          <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet.
            </p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Reduce operational costs & Improve skills
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Risk-free implementation of IT changes
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Improve accountability through standardization
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Improve accountability within business functions
            </p>
     
          </div>
          <div className="flex flex-col gap-2">
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Reduce operational costs & Improve skills
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Risk-free implementation of IT changes
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Improve accountability through standardization
            </p>
            <p className="flex items-center gap-3">
              <FiCheckSquare />
              Improve accountability within business functions
            </p>
        
          </div>
            </div>
           
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
