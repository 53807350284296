import work1 from "../images/work1.svg"
import work2 from "../images/work2.svg"
import work3 from "../images/work3.svg"
import work4 from "../images/work4.svg"

export const WorkData = [
    {
        id : 1,
        img : work1,
        name : "Book Appontment",
        para :"Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore."
    },
    {
        id : 2,
        img : work2,
        name : "Meet Our Team",
        para :"Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore."
    },
    {
        id : 3,
        img : work3,
        name : "Get Estimation",
        para :"Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore."
    },
    {
        id : 4,
        img : work4,
        name : "Start Your Project",
        para :"Lorem ipsum dolor amet consectetur do eiusmod tempor incididunt and is labore."
    },
]