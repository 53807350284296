import React from "react";
import logo2 from "../images/Logo2.svg";
import { Link } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import { HiOutlinePhone } from "react-icons/hi";

const Footer = () => {
  const siteLink = "https://troothemes.com/"
  return (
    <div className="bg-footer-Bg bg-no-repeat w-full h-full relative z-[1] before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:opacity-95 before:z-[-1]">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 py-10 ">
          <Link to="/" className="mb-8 md:mb-0">
            <img src={logo2} alt="logo" />
          </Link>
          <div className="flex justify-center items-center text-troo-white gap-5 lg:border-l border-troo-white border-opacity-20 ">
            <div>
              <HiOutlinePhone />
            </div>
            <div>
              <p>Call</p>
              <h3>+44 123 456 7890</h3>
              <h3>+44 123 456 7890</h3>
            </div>
          </div>
          <div className="flex justify-center md:justify-end items-center text-troo-white gap-5 lg:border-l border-troo-white border-opacity-20">
            <div>
              <FiMail />
            </div>
            <div>
              <p>Email</p>
              <h3>trooinfo@email.com</h3>
              <h3>trooinfo@email.com</h3>
            </div>
          </div>
        </div>
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-7 w-full lg:gap-12 py-12 border-y border-troo-white border-opacity-20">
          <div className="flex flex-col md:flex-row justify-between gap-10 text-troo-white">
            <div className="flex flex-col gap-5">
              <h4 className=" relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4  ">
                About Us
              </h4>
              <p className="md:w-[350px] w-full">
                Lörem ipsum od ohet dilogi. Bell trabel, samuligt, ohöbel utom
                diska. Jinesade bel när feras redorade
              </p>

              <h3>Ready to get started?</h3>
              <div>
                <Link
                  to="/contact-us"
                  className="btn1 bg-troo-primary text-troo-secondary hover:text-troo-primary hover:bg-troo-white"
                >
                  CONTACT US
                </Link>
              </div>
            </div>
            <div>
              <h4 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4  ">
                Navigation
              </h4>
              <nav className="flex flex-col gap-4">
                <Link>About us</Link>
                <Link>Our Blogs</Link>
                <Link>Our Portfolio</Link>
                <Link>Jobs</Link>
                <Link>Contact Us</Link>
              </nav>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-8 justify-around text-troo-white">
            <div>
              <h4 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4">
                Our Services
              </h4>
              <nav className="flex flex-col gap-4">
                <Link>Apartment Design</Link>
                <Link>Expert Mechanical</Link>
                <Link>Architecture & Building</Link>
                <Link>Heavy Equipment</Link>
                <Link>Repair & Painting</Link>
              </nav>
            </div>
            <div className="border border-troo-white border-opacity-20 bg-troo-secondary rounded p-5">
              <h3 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4">
                Newsletter
              </h3>
              <form className="w-full flex flex-col gap-6">
                <input
                  type="text"
                  placeholder="Your Email"
                  className="px-6 py-3 rounded bg-transparent border border-troo-white border-opacity-20 "
                />
                <button className="btn1 bg-troo-primary hover:bg-troo-white hover:text-troo-secondary">
                  SUBSCRIBE NOW!
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="py-2 md:flex justify-between text-troo-white">
          <p>Copyright © 2023. <Link to={siteLink} className="font-bold"> troothemes </Link>. All rights reserved.</p>
          <div className="flex gap-5">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
