import React from "react";

const Input = ({ placeholder, isservice, type, icon, ...rest }) => {
  return (
    <div className={`flex items-center w-full px-5 py-4 justify-between border ${isservice ? "border-troo-white" :" border-troo-whitefade2 rounded "}`}>
      <input
        type={type}
        placeholder={placeholder}
        {...rest}
        className={` bg-transparent outline-none w-full ${
          isservice ? "placeholder-troo-white  " : " placeholder-troo-secondary"
        }`}
      />
      <div className={` text-xl cursor-pointer ${isservice ? "text-troo-white" : "text-troo-secondary"}`}>{icon}</div>
    </div>
  );
};

export default Input;
