import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Faqs from '../../Components/Faqs'
import BrandLogos from '../../Components/BrandLogos'
import ServiceDetails from '../../Components/ServiceDetails'
import CaseStudy from '../../Components/CaseStudy'
import Service from '../../Components/Service'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServiceDetailsPage = () => {
  useDocumentTitle("TRoo Construction | Service Details")
  return (
    <Layout>
        <BannerGlobal/>
        <ServiceDetails/>
        <CaseStudy isDetails/>
        <Service isDetails/>
        <Faqs/>
        <BrandLogos/>
    </Layout>
  )
}

export default ServiceDetailsPage