export const FaqData = [
  {
    id: 1,
    name: "How do I choose the ‘right builder’?",
    para: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
  },
  {
    id: 2,
    name: "How do I get the most from my construction project?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 3,
    name: "What are the keys to success in any construction or renovation?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 4,
    name: "What is ‘quality’ in construction?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 5,
    name: "What is the difference between a qualified builder & registered master builder?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 6,
    name: "How do I get the most from my construction project?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 7,
    name: "What are the keys to success in any construction or renovation?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 8,
    name: "What is ‘quality’ in construction?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 9,
    name: "What is the difference between a qualified builder & registered master builder?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  
];
